<template>
  <div class="container-full">
    <div class="breadcrumbs">
      <div class="container">
        <span v-for="link in paths" :key="link.route_link">
          <router-link :to="link.route_link">
            {{ link.name | htmlDecode }}
          </router-link>
        </span>
        <span class="cl-mine-shaft">
          {{ current | htmlDecode }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { Breadcrumbs } from '@vue-storefront/core/modules/breadcrumbs/components/Breadcrumbs.ts'
import { localizedRoute, currentStoreView } from '@vue-storefront/core/lib/multistore'
import i18n from '@vue-storefront/i18n'

export default {
  mixins: [Breadcrumbs],
  computed: {
    paths () {
      const routes = this.routes ? this.routes : this.getBreadcrumbsRoutes

      return [
        { name: i18n.t('Home'), route_link: localizedRoute('/', currentStoreView().storeCode) },
        ...routes.filter((e) => e.name !== 'Home')
      ]
    }
  }
}
</script>
