<template>
  <div>
    <breadcrumbs
      :with-homepage="true"
      :routes="[]"
      :active-route="title"
    />
    <section class="section-contacts col-layout container">
      <div class="row">
        <div class="col-xs-12">
          <h1 class="page-title h2">
            {{ title }}
          </h1>
        </div>

        <div class="col-xs-12 col-sm-6">
          <div v-if="description" v-html="description" class="description" />
          <form @submit.prevent="sendMessage" novalidate="novalidate">
            <base-input
              type="text"
              name="name"
              autocomplete="name"
              required="required"
              v-model="name"
              :placeholder="$t('Name *')"
            />
            <base-input
              type="email"
              name="email"
              autocomplete="email"
              required="required"
              v-model="email"
              :placeholder="$t('E-mail address *')"
            />
            <base-input
              type="tel"
              name="telephone"
              autocomplete="tel"
              v-model="telephone"
              :placeholder="$t('Phone Number')"
            />
            <base-textarea
              type="textarea"
              name="comment"
              row="3"
              v-model="message"
              :placeholder="$t('Kommentar')"
            />
            <br>
            <base-checkbox id="privacy-agreement" v-model="privacy">
              Ja, ich stimme der Verarbeitung meiner Daten gemäß der <router-link :to="localizedRoute('/datenschutzerklaerung')">
                Datenschutzerklärung
              </router-link> zu.
            </base-checkbox>
            <br>

            <div class="clearfix action">
              <p class="pull-left required-fields">
                * Pflichtfelder
              </p>
              <button type="submit" title="Absenden" class="btn btn-primary pull-right">
                Absenden
              </button>
            </div>
          </form>
        </div>

        <div class="col-xs-12 col-sm-4 col-sm-offset-2">
          <p><strong>decoga GmbH</strong><br> Otto-Hahn-Str. 23<br> D-50997 Köln<br> Deutschland</p>
          <p><strong>Tel.</strong> <a href="tel:00492236333666" target="_blank">+49 2236 / 333 666</a><br> <strong>E-Mail:</strong> info@zaun24.de<br> <strong>WhatsApp:</strong> <a href="https://wa.me/4922363336612?text=Hallo%20liebes%20ZAUN24-Team%2C%0D%0A" target="_blank">+49 2236 / 333 66-12</a></p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from '../components/core/Breadcrumbs'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput.vue'
import BaseTextarea from 'theme/components/core/blocks/Form/BaseTextarea.vue'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox.vue'
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'

import { EmailForm } from '@vue-storefront/core/modules/mailer/components/EmailForm'
import { MailerModule } from '@vue-storefront/core/modules/mailer'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { mapGetters } from 'vuex'

export default {
  components: {
    Breadcrumbs,
    BaseInput,
    BaseTextarea,
    BaseCheckbox
  },
  mixins: [EmailForm],
  beforeCreate () {
    registerModule(MailerModule)
  },
  data () {
    return {
      name: '',
      email: '',
      telephone: '',
      message: '',
      privacy: false
    }
  },
  props: {
    page: String,
    title: String
  },
  computed: {
    ...mapGetters({
      storeConfig: 'storeConfig/getConfigValue'
    }),
    description () {
      let result = false;

      if (this.$route.name === 'contacts-b2b') {
        result = '<p>Sind Sie gewerblicher Kunde oder Wiederverkäufer?</p><p>Falls ja, beliefern wir Sie selbstverständlich auch sehr gerne. Wir bieten für Wiederverkäufer keine pauschalen Rabatte an, sondern erstellen Ihnen für jedes Projekt ein individuelles Angebot.</p><p>Bitte senden Sie uns Ihre Anfrage mit dem Formular auf dieser Seite. Wir antworten Ihnen schnellstmöglich.</p>';
      }

      return result;
    }
  },
  methods: {
    sendMessage () {
      if (!this.privacy) {
        this.onFailure('Bitte bestätigen Sie den Hinweis zur Datenverarbeitung.');
        return;
      }

      this.sendEmail(
        {
          sourceAddress: this.email,
          targetAddress: this.storeConfig('contact/email/recipient_email'),
          subject: (this.$route.name === 'contacts-b2b') ? this.$t('Commercial Form Request') : this.$t('Contact Form Request'),
          emailText: this.buildMessage()
        },
        this.onSuccess,
        this.onFailure
      )
    },
    buildMessage () {
      return Object
        .entries(this._data)
        .flatMap((v) => v[0].charAt(0).toUpperCase() + v[0].slice(1) + ': ' + v[1]).join('\n')
    },
    onSuccess (message) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message,
        action1: { label: this.$t('OK') }
      })
      /*
      if (this.mailerElements.sendConfirmation) {
        this.sendEmail(
          {
            sourceAddress: this.mailerElements,
            targetAddress: this.checkoutPersonalEmailAddress,
            subject: this.$t('Confirmation of receival'),
            emailText: this.$t(`Dear customer,\n\nWe have received your letter.\nThank you for your feedback!`),
            confirmation: true
          }
        )
      }
      */
    },
    onFailure (message) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message,
        action1: { label: this.$t('OK') }
      })
    }
  }
}
</script>
